import { React, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './ts-mule.css';
import '../components.css';

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}

export function TSMule() {
    return (
        <section id="ts-mule" className="components">
            <ScrollToTopOnMount />

            <div className="return">
                <Link to="/">[ Return ]</Link>
            </div>

            <Container fluid className="logos">
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}>
                        <img className="logo" src="images/logos/dbvis-logo.png" alt="DBVIS Logo" />
                    </Col>
                    <Col></Col>
                    <Col sm={2}>
                        <img className="logo" src="images/logos/unikn-logo.png" alt="UNI KN Logo" />
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h1>
                            TS-MULE: Local Interpretable Model-Agnostic <br></br>
                            Explanations for Time Series Forecast Models
                        </h1>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col>
                        <h3>
                            Udo Schlegel<sup>1</sup>, Duy Vo Lam<sup>1</sup>, Daniel A. Keim
                            <sup>1</sup>, Daniel Seebacher<sup>1</sup>
                        </h3>
                        <h3>
                            <sup>1</sup> University of Konstanz
                        </h3>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col className="text-align-center" sm={10}>
                        <a href="https://project.inria.fr/aimlai/previous-editions/aimlai-ecml-2021/program/">
                            [ Advances in Interpretable Machine Learning and Artificial Intelligence
                            (AIMLAI @ ECML-PKDD 2021), Bilbao, Spain ]
                        </a>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col className="text-align-center">
                        <a href="https://arxiv.org/pdf/2109.08438.pdf">[ Paper ]</a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://arxiv.org/abs/2109.08438">[ arXiv ]</a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://project.inria.fr/aimlai/files/2021/09/TS-MULE-Presentation.pdf">
                            [ Presentation ]
                        </a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://github.com/visual-xai-for-time-series/ts-mule">[ Code ]</a>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            <span className="bold">
                                Local Interpretable Model-Agnsotic Explanations [1]
                            </span>{' '}
                            introduces an approach to generate attributions based on a locally
                            trained surrogate model. The method first{' '}
                            <span className="bold">segments the input data</span> into meaningful
                            segments and uses these segments to create{' '}
                            <span className="bold">
                                masks to generate perturbed input instances
                            </span>
                            . Thus, the masks remove information from the original input to
                            investigate the models' behavior. Based on the model's predictions for
                            the perturbed instances,{' '}
                            <span className="bold">a linear surrogate model is trained</span>, and
                            the{' '}
                            <span className="bold">coefficients represent the attributions</span>.
                        </p>
                        <p>
                            Segmentation algorithms are thus a very important component of the
                            technique. However, e.g.,{' '}
                            <span className="bold">
                                for time series, there is not a segmentation technique that works
                                best
                            </span>{' '}
                            for nearly all inputs, such as superpixel segmentation in images. More{' '}
                            <span className="bold">dynamic</span> segmentations are needed.
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2021-ts-mule/TS-MULE_Workflow.png"
                            alt="Segmentations"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Exploring segmentation techniques for time series</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2021-ts-mule/AIMLAI_2021___TS_MULE_Comparison.png"
                            alt="XAI for Time Series"
                        />
                    </Col>
                    <Col>
                        <p>
                            Segmentation techniques for time series are{' '}
                            <span className="bold">relatively simple for static cases</span> but{' '}
                            <span className="bold">difficult for dynamic cases</span>. For simple
                            cases, the default segmentation can be{' '}
                            <span className="bold">uniform, leading to solid results</span>. In this
                            case, a fixed window size of x is defined, and the{' '}
                            <span className="bold">time series is cut every x time points</span>{' '}
                            forming segments with the length of the window size.
                        </p>
                        <p>
                            However, such a rather simple segmentation can{' '}
                            <span className="bold">
                                cut at essential time points and ignore relevant segment patterns
                            </span>
                            . Thus we propose another variant with the assumption for forecasting
                            that the{' '}
                            <span className="bold">
                                last few time points are more critical for the prediction
                            </span>
                            . In our exponential segmentation, we introduce an{' '}
                            <span className="bold">exponentially growing window size</span>,
                            starting with a small value and getting larger quickly. However, such a
                            segmentation technique is still relatively static and{' '}
                            <span className="bold">mitigates the dynamic property</span> of many
                            time series.
                        </p>
                        <p>
                            Thus, we introduce the <span className="bold">matrix profile [2]</span>{' '}
                            to identify sub-sequences which are more coherent in the time series.
                            The matrix profile{' '}
                            <span className="bold">
                                uses a z-normalized Euclidean distance on a sliding window approach
                                in the time series to identify motifs or outliers [2]
                            </span>
                            . Simply speaking, the matrix profile wants to find the distance between
                            the most similar sub-sequences in a longer time series with a fixed
                            winodw size of the sub-squence. We incorporate this property as we are
                            interested in similar interesting or outlier patterns. We{' '}
                            <span className="bold">
                                sort the matrix profile and calculate the slope
                            </span>{' '}
                            in the first case. If we have a high increase or decrease in the slopes,
                            we have a <span className="bold">plausbile jump</span> in the distances
                            and thus a possible splitting point. We use the unsorted case to find
                            slope jumps directly.
                        </p>
                        <p>
                            At last, we incorporate the{' '}
                            <span className="bold">Symbolic Aggregate approXimation (SAX)</span> to
                            generate longer sub-sequences with{' '}
                            <span className="bold">similar properties lying in a region band</span>.
                            In this case, we divide the vertical space into region bands and assign
                            fixed windows to the different region bands. These region bands have a
                            symbolic representation, and we transform our time series to this
                            representation.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Experiment</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            To test our different segmentations and the attributions they produce,
                            we apply a perturbation analysis to capture the fidelity of the
                            attributions to the model. Perturbations of time series{' '}
                            <span className="bold">based on XAI technique explanations</span> can be
                            applied to various test data. With such a modified version,{' '}
                            <span className="bold">measuring the change in a quality metric</span>{' '}
                            for the underlying model is possible. However, there are multiple
                            methods to perturb time series. We focus on{' '}
                            <span className="bold">our previous work [3]</span> and apply the
                            proposed techniques for the attributions of our segmentation approaches.
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2020-empirical-study/xai-verification.png"
                            alt="XAI for Time Series"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2020-empirical-study/experiment.png"
                            alt="XAI for Time Series"
                        />
                    </Col>
                    <Col>
                        <p>
                            We use the following concept to capture the fidelity:
                            <br />
                            <span className="bold">Model training:</span> train deep learning
                            architecture on a fixed dataset and predict test data to get a baseline.
                            <br />
                            <span className="bold">Model explanation:</span>{' '}
                            <span className="italic">1.</span> Apply available segmentation and
                            attribution technique on the trained model to generate explanations.{' '}
                            <span className="italic">2.</span> Modify test data based on the
                            explanations and use the modified test data for another prediction.{' '}
                            <span className="italic">3.</span> Create random explanations and do the
                            same.
                            <br />
                            <span className="bold">Explanation evaluation:</span> compare the
                            prediction of the test data to the predictions of the modified test
                            data. If the explanation is sufficient, the second prediction is worse
                            than the first and the random prediction.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Results</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            As a quality metric for forecasting, we use the{' '}
                            <span className="bold">Root Mean Squared Error (RMSE)</span> and thus
                            focus on higher values, as a low value indicates a small error while a
                            large value indicates flaws in the model. We further incorporate a
                            <span className="bold">
                                random attribution as a baseline for each segmentation
                            </span>{' '}
                            as each segmentation produces a different amount of segments and thus{' '}
                            <span className="bold">a different granularity of explanation</span>. We
                            need to normalize our results by such a baseline. Our results indicate
                            that{' '}
                            <span className="bold">
                                our proposed matrix profile helps to identify relevant time points
                                toward longer inputs
                            </span>{' '}
                            for models. The uniform approach generally holds valuable information
                            and is a good baseline for every input. However, for improved results,
                            the segmentation can be changed to include more properties of the model
                            and the data.
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2021-ts-mule/TS-MULE_Results.png"
                            alt="XAI for Time Series"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center">
                    <Col sm={1}></Col>
                    <Col>
                        <p className="references">
                            [1] Ribeiro, M. T., Singh, S., & Guestrin, C. (2016, August). "Why
                            should i trust you?" Explaining the predictions of any classifier. In
                            Proceedings of the 22nd ACM SIGKDD international conference on knowledge
                            discovery and data mining (pp. 1135-1144).
                        </p>
                        <p className="references">
                            [2] Yeh, C. C. M., Zhu, Y., Ulanova, L., Begum, N., Ding, Y., Dau, H.
                            A., ... & Keogh, E. (2016, December). Matrix profile I: all pairs
                            similarity joins for time series: a unifying view that includes motifs,
                            discords and shapelets. In 2016 IEEE 16th international conference on
                            data mining (ICDM) (pp. 1317-1322).
                        </p>
                        <p className="references">
                            [3] Schlegel, U., Arnout, H., El-Assady, M., Oelke, D., & Keim, D. A.
                            (2019). Towards a rigorous evaluation of XAI Methods on Time Series.
                            arXiv preprint arXiv:1909.07082
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Container>
        </section>
    );
}


import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './about.css';

export function About() {
    return (
        <section id="about">
            <Container fluid>
                <Row className="align-items-center">
                    <Col sm={2}></Col>
                    <Col>
                        <h2>About Visual Explainable AI for Time Series</h2>

                        <p>
                            Deep learning models, while powerful, are often overlooked for{' '}
                            <span className="bold-highlight">time series tasks</span> in real-world
                            applications, primarily because their internal workings and
                            decision-making processes are not easily interpretable. This opacity
                            poses a significant challenge, particularly in industries where
                            understanding and trust in the decision-making process are crucial. To
                            address this issue, the emerging field of{' '}
                            <span className="bold-highlight">
                                visual explainable AI (XAI) for time series
                            </span>{' '}
                            is dedicated to developing methodologies that not only harness the
                            predictive power of{' '}
                            <span className="bold-highlight">
                                state-of-the-art deep learning architectures
                            </span>{' '}
                            for these tasks but also provide intuitive and understandable
                            explanations for their decisions. This approach aims to make the models
                            more <span className="bold-highlight">transparent</span> and their
                            outputs more <span className="bold-highlight">accessible</span> to
                            users, thereby fostering greater{' '}
                            <span className="bold-highlight">trust</span> and broader{' '}
                            <span className="bold-highlight">adoption</span> in practical settings.
                        </p>
                        <p>
                            The project envisages breaking down this ambitious goal into manageable{' '}
                            <span className="bold-highlight">sub-projects</span>, each focusing on
                            specific aspects of the challenge. These sub-projects will explore
                            various dimensions of{' '}
                            <span className="bold-highlight">explainability</span>, such as{' '}
                            <span className="bold-highlight">visual interpretation methods</span>,
                            the development of{' '}
                            <span className="bold-highlight">metrics for explainability</span>, and
                            the integration of <span className="bold-highlight">user feedback</span>{' '}
                            into model understanding and debugging. By disseminating the outcomes of
                            these sub-projects through{' '}
                            <span className="bold-highlight">student theses</span> or{' '}
                            <span className="bold-highlight">publications</span> at{' '}
                            <span className="bold-highlight">conferences</span>, the project seeks
                            to contribute significantly to the body of knowledge in the field.
                            Additionally, it aims to encourage{' '}
                            <span className="bold-highlight">collaboration</span> across academic
                            and industrial boundaries, fostering innovation and pushing the
                            boundaries of what's currently possible in explainable AI for time
                            series analysis.
                        </p>

                        <p>
                            Projects have their <span className="bold-highlight">source code</span>{' '}
                            published online to reproduce the experiment results, run the
                            application locally, and use it for further research opportunities.
                            <br></br>
                            These projects are available on:{' '}
                            <a href="https://github.com/visual-xai-for-time-series">
                                https://github.com/visual-xai-for-time-series
                            </a>
                            .
                        </p>

                        <br />

                        <h3>Contact</h3>
                        <p>
                            Udo Schlegel, University of Konstanz,{'   '}
                            <a href="mailto:u.schlegel@uni-konstanz.de">
                                u.schlegel@uni-konstanz.de
                            </a>
                        </p>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
            </Container>
        </section>
    );
}


import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import $ from 'jquery';

import './header.css';

export function Header() {
    useEffect(() => {
        $('body').on('click', '.smoothscroll', function (e) {
            e.preventDefault();

            var target = this.hash,
                $target = $(target);

            $('html, body')
                .stop()
                .animate(
                    {
                        scrollTop: $target.offset().top,
                    },
                    800,
                    'swing',
                    function () {
                        window.location.hash = target;
                    }
                );
        });

        $(window).on('scroll', function () {
            var h = $('header').height();
            var y = $(window).scrollTop();
            var nav = $('#nav-wrap');

            if (y > h * 0.2 && y < h && $(window).outerWidth() > 768) {
                nav.fadeOut('fast');
            } else {
                if (y < h * 0.2) {
                    nav.removeClass('opaque').fadeIn('fast');
                } else {
                    nav.addClass('opaque').fadeIn('fast');
                }
            }
        });
    }, []);

    return (
        <header id="front">
            <nav id="nav-wrap">
                <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
                    Show navigation
                </a>
                <a className="mobile-btn" href="#front" title="Hide navigation">
                    Hide navigation
                </a>

                <ul id="nav" className="nav">
                    <li className="current">
                        <a className="smoothscroll" href="#front">
                            Front
                        </a>
                    </li>
                    <li>
                        <a className="smoothscroll" href="#about">
                            About
                        </a>
                    </li>
                    <li>
                        <a className="smoothscroll" href="#projects">
                            Projects
                        </a>
                    </li>
                    <li>
                        <a className="smoothscroll" href="#publications">
                            Publications
                        </a>
                    </li>
                    <li>
                        <a className="smoothscroll" href="#about">
                            Contact
                        </a>
                    </li>
                </ul>
            </nav>

            <div className="banner">
                <div className="banner-text">
                    <Container fluid>
                        <Row className="align-items-center">
                            <Col sm={8}>
                                <h1>Visual Explainable AI for Time Series</h1>
                                <h3>
                                    establishing a framework for explainable artifical intelligence
                                    for time series deep learning classifiers using attributions and
                                    counterfactuals.
                                </h3>
                            </Col>
                            <Col sm={4}>
                                <img
                                    className="logo-header"
                                    src="images/vxaitsv3.png"
                                    alt="Visual Explainable AI for Time Series"
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <p className="scrolldown">
                <a className="smoothscroll" href="#about">
                    <i className="fa fa-chevron-circle-down"></i>
                </a>
            </p>
        </header>
    );
}


import { React, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './review.css';

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}

export function Review() {
    return (
        <section id="review" className="components">
            <ScrollToTopOnMount />

            <div className="return">
                <Link to="/">[ Return ]</Link>
            </div>

            <Container fluid className="logos">
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}>
                        <img
                            className="logo"
                            src="images/logos/Aalen-Applied-University-Germany.png"
                            alt="AALENHS Logo"
                        />
                        <img
                            className="logo"
                            src="images/logos/Scuola-Normale-Superiore-Pisa-Italy.png"
                            alt="SNS Logo"
                        />
                    </Col>
                    <Col></Col>
                    <Col sm={2}>
                        <img className="logo" src="images/logos/unikn-logo.png" alt="UNI KN Logo" />
                        <img
                            className="logo"
                            src="images/logos/University-of-Pisa-Italy.png"
                            alt="UNI PISA Logo"
                        />
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h1>
                            Explainable AI for Time Series Classification: <br></br>A Review,
                            Taxonomy and Research Directions
                        </h1>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col sm={8}>
                        <h3>
                            Andreas Theissler<sup>1</sup> <span className="text-divider"></span>
                            Francesco Spinnato<sup>2</sup> <span className="text-divider"></span>
                            Udo Schlegel<sup>3</sup> <span className="text-divider"></span>Riccardo
                            Guidotti<sup>4</sup>
                        </h3>
                        <h3>
                            <sup>1</sup> Aalen University of Applied Sciences{' '}
                            <span className="text-divider"></span>
                            <sup>2</sup> Scuola Normale Superiore <br></br>
                            <span className="text-divider"></span>
                            <sup>3</sup> University of Konstanz{' '}
                            <span className="text-divider"></span>
                            <sup>4</sup> University of Pisa <span className="text-divider"></span>
                        </h3>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col className="text-align-center" sm={8}>
                        <a href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=9668973&punumber=6287639">
                            [ IEEE Access, Volume 10 ]
                        </a>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col className="text-align-center">
                        <a href="https://ieeexplore.ieee.org/document/9895252">[ Paper ]</a>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2022-ts-xai-review/Categorization.gif"
                            alt="XAI for time series categorization"
                        />
                    </Col>
                    <Col>
                        <p>
                            <span className="bold">Explainable AI (XAI)</span> bridges the gap
                            between state-of-the-art deep learning and production-ready models. Deep
                            neural networks{' '}
                            <span className="bold">lack explainable decision making</span> and are
                            often not suitable for production as decisions{' '}
                            <span className="bold">cannot be trusted</span> and are{' '}
                            <span className="bold">challenging to debug</span>. XAI techniques are
                            developed to interpret such model decisions and to{' '}
                            <span className="bold">generate comprehensible explanations.</span>
                        </p>
                        <p>
                            Sensors{' '}
                            <span className="bold">generate massive amounts of time series</span>{' '}
                            with a variety of tasks for machine learning models. Nowadays, more and
                            more deep neural networks are trained on tasks such as{' '}
                            <span className="bold">anomaly detection</span>.
                        </p>
                        <p>
                            Most XAI techniques aiding in understanding and debugging are{' '}
                            <span className="bold">developed for images or text.</span> However, we
                            argue to design XAI techniques for time series data. We categorize
                            different explanation types into{' '}
                            <span className="bold">four groups tackling different granularity</span>{' '}
                            of time series.
                        </p>
                        <p>
                            Our <span className="bold">time points based</span> explanations consist
                            of, e.g., attributions and focus on single time points. Then, using more
                            than one point into intervals, we get to{' '}
                            <span className="bold">subsequences based</span> explanations such as
                            shapelets. Next, we use not only intervals, but the whole time series
                            input to get <span className="bold">instance based</span> explanations,
                            e.g., counterfactuals. Lastly, <span className="bold">others</span>{' '}
                            collect everything in between or we didnt think about.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>XAI approaches for time series classification</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            Based on our categorization, we collected papers from various sources.
                            On the one hand, we included a{' '}
                            <span className="bold">stratified Scopus search</span> while also using
                            our previous works and collected papers we cited our{' '}
                            <span className="bold">found online</span>. We identified{' '}
                            <span className="bold">73</span> important papers for our categories,
                            reviewed, and sorted them into these. Our results can be seen in the
                            table here.
                        </p>
                        <p>
                            The sorting of the approaches corresponds to the appearance of the text.
                            These are sorted by the explanation type and then method.{' '}
                            <span className="bold">Our columns are features</span> of these
                            approaches for easier identification of differences. Our first feature
                            is the application of the method{' '}
                            <span className="bold">
                                before (Ante-hoc: A) or after (Post-hoc: P)
                            </span>{' '}
                            the model training. Next, the usage on{' '}
                            <span className="bold">
                                all models (Model-Agnostic: A) or on just a certain kind of model
                                (Model-Specific: S)
                            </span>
                            . Then, if the explanation provides{' '}
                            <span className="bold">
                                an overall (Global: G) or just a small (Local: L) explanation
                            </span>{' '}
                            for the model. Afterward, we checked if the XAI approach is{' '}
                            <span className="bold">
                                focused on time series only (TS-Specific: ✓)
                            </span>
                            . For time series methods, if these can be used on just{' '}
                            <span className="bold">
                                one (Univariate: U) or multiple (Multivariate: M)
                            </span>
                            . We also searched online for implementations of the approaches:
                            P-Python, M-Matlab, J-Java, JS-Javascript. On the PDF, the code letters
                            are hyperlinks to official web pages. We considered all Ante-hoc methods
                            as Model-Specific methods.
                        </p>
                        <p>
                            Best viewed in the{' '}
                            <a href="https://ieeexplore.ieee.org/stamp/stamp.jsp?tp=&arnumber=9895252">
                                <span className="bold">PDF here</span>
                            </a>
                            .
                        </p>
                    </Col>
                    <Col className="align-items-center">
                        <img
                            className="images-text"
                            src="images/2022-ts-xai-review/Table-Overall.gif"
                            alt="XAI approaches for Time Series"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Evaluations of XAI approaches</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2022-ts-xai-review/Table-Evaluation.gif"
                            alt="XAI evaluations for time series categorization"
                        />
                    </Col>
                    <Col>
                        <p>
                            Overview of Evaluations Conducted in the Reviewed Papers. The Lack of
                            User Studies to Evaluate Interpretability Becomes Obvious. (Frequently
                            Used Evaluation Methods are Shown With the Abbreviations: ACC =
                            Classification Accuracy, EX = Author-Selected Examples and Visual
                            Presentation, PERTURB = Pertubation Analysis, RT = Runtime, US = User
                            Study, QUANTCOMP = Quantitative Comparison to Other Methods, POINT =
                            Pointing Game in localization)
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Research Directions</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col>
                        <h3>Higher-Order Explanations Are Desirable</h3>
                        <p>
                            We argue that current visualizations are not sufficient in those cases
                            where the pure signal cannot be directly interpreted. We see an
                            opportunity for higher-order representations besides line plots of the
                            explanations to enable a more straightforward explanation. Based on such
                            other representations, we further highlight the importance of
                            explanations that are not purely visualizations.
                        </p>
                        <div className="divider"></div>
                        <h3>Model-Agnostic Approaches Are Particularly Useful for TSC</h3>
                        <p>
                            In contrast to, e.g., computer vision applications, deep learning
                            methods do currently not clearly dominate the field. In the search for
                            the best method for a given problem setting, deep learning, ensembles,
                            distance-based methods, shapelets and further methods are used. In order
                            to compare the interpretability of these entirely different model
                            architectures, model-agnostic methods are required.
                        </p>
                        <div className="divider"></div>
                        <h3>Domain-Specific Explanations for Specific Applications</h3>
                        <p>
                            We believe some cases require domain-specific explanations when
                            explaining the models to end users. Indeed, the effectiveness of an
                            explanation depends on the user’s perception and response rather than on
                            the model. In particular, the end user may not be able to understand all
                            the information even if a model is made entirely transparent.
                        </p>
                        <div className="divider"></div>
                        <h3>Easy-to-Use Explainers are Desirable</h3>
                        <p>
                            Explaining complex ML models calls for sophisticated and often
                            complicated XAI methods. For example, some XAI methods come with
                            assumptions like local linearity or feature independence and many
                            hyperparameters, e.g., to control approximations of computationally
                            expensive explanations.
                        </p>
                        <div className="divider"></div>
                        <h3>More Rigorous Evaluation of Explanations is Needed</h3>
                        <p>
                            further methods for faithfulness analysis need to be implemented and
                            generally be used to establish a rigorous quantitative and automatic
                            evaluation. Finally, focusing on verbalization and not only
                            visualization can help improve such evaluations even more, as possibly
                            faulty explanations can be identified more easily.
                        </p>
                        <div className="divider"></div>
                        <h3>Evaluation Should Also Address Human Interpretability</h3>
                        <p>
                            We observed that research in the field often addresses the part of
                            explainability, i.e. explaining a model by means of e.g. a
                            visualisation, shapelets or prototypes. These artefacts are then shown
                            with author-selected examples or in some cases evaluated quantitatively.
                            Whether the artefacts are indeed interpretable for the target audience
                            is an evaluation that is not regularly conducted. The artefacts are, by
                            definition, assumed to be interpretable.
                        </p>
                        <div className="divider"></div>
                        <h3>Unified Implementation of XAI for Comparative Evaluation</h3>
                        <p>
                            While many of the reviewed XAI methods provide source code (see overall
                            Table), there is no unified library that allows for easy comparative
                            evaluation of XAI methods
                        </p>
                        <div className="divider"></div>
                        <h3>Benchmark Data Sets for Evaluation are Desirable</h3>
                        <p>
                            The evaluation process has a number of parameters itself, which might
                            lead to different results when being used by different researchers. An
                            idea could be to have gold-standard datasets for the evaluation of
                            explanations: time series that are annotated, i.e., subsequences, data
                            points or higher order features that are known to be discriminative are
                            annotated.
                        </p>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
            </Container>
        </section>
    );
}


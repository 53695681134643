// import React, { useState, useEffect } from 'react';
import { Header } from './header';
import { About } from './about';
import { Projects } from './projects';
import { Contact } from './contact';
import { Publications } from './publications';

export function Main() {

    return (
      <div className="Main">
        <Header />
        <About />
        <Projects />

        <Publications />

        <Contact />
      </div>
    );

}

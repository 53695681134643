import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './projects.css';

export function Projects() {
    useEffect(() => {}, []);

    return (
        <section id="projects">
            <div>
                <h1>Content</h1>
            </div>
            <Container fluid>
                <Row sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Background</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row sm={12}>
                    <Col sm={1}></Col>
                    <Col sm={3}>
                        <Link to="/explainer">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                explAIner: A Visual Analytics Framevwork for Interactive and
                                Explainable Machine Learning
                            </button>
                            <div style={{ height: '30px', width: '100%' }} />
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2019-explainer/explainer-motivation.png"
                                alt="The explAIner motivation"
                            />
                            <div style={{ height: '30px', width: '100%' }} />
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2019-explainer/explainer-framework.png"
                                alt="The explAIner framework"
                            />
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/time-series-xai-review">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                Explainable AI for Time Series Classification: <br></br>A Review,
                                Taxonomy and Research Directions
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2022-ts-xai-review/Categorization.gif"
                                alt="Review, Taxonomy, Research Directions"
                            />
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/vis-for-att">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                Time series model attribution visualizations as explanations
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2021-attribution-visualizations-as-explanations/vis-for-att-1.png"
                                alt="TS XAI Visualizations"
                            />
                            <div style={{ height: '30px', width: '100%' }} />
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2021-attribution-visualizations-as-explanations/vis-for-att-2.png"
                                alt="TS XAI Visualizations"
                            />
                        </Link>
                    </Col>
                    <Col sm={1}></Col>
                </Row>

                <Row className="divider"></Row>

                <Row sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Generation of Time Series Explanations</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row sm={12}>
                    <Col sm={1}></Col>
                    <Col sm={3}>
                        <Link to="/ts-mule">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                TS-MULE: Local Interpretable Model-Agnostic Explanations<br></br>{' '}
                                for Time Series Forecast Models
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2021-ts-mule/TS-MULE_Workflow.png"
                                alt="TS-MULE"
                            />
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/icfts">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                Interactive Generation of Counterfactuals for Time Series
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2024-icfts/icfts-overview.png"
                                alt="Interactive Generation of Counterfactuals for Time Series"
                            />
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/veac">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                Visual Explanations with Attributions and Counterfactuals on Time
                                Series Classification
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2024-veac/veac-workflow.png"
                                alt="Visual Explanations with Attributions and Counterfactuals on Time
                                Series Classification"
                            />
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2024-veac/veac-application.png"
                                alt="Visual Explanations with Attributions and Counterfactuals on Time
                                Series Classification"
                            />
                        </Link>
                    </Col>
                    <Col sm={1}></Col>
                </Row>

                <Row className="divider"></Row>

                <Row sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Evaluation of Time Series Explanations</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row sm={12}>
                    {/* <Col sm={1}></Col> */}
                    <Col sm={3}>
                        <Link to="/pre-study">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                Towards a Rigorous Evaluation of XAI Methods on Time Series
                            </button>
                            <div style={{ height: '10px', width: '100%' }} />
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2020-empirical-study/xai-time-series.png"
                                alt="A Preliminary Study"
                            />
                            <div style={{ height: '30px', width: '100%' }} />
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2019-preliminary-study/preliminary-study.png"
                                alt="A Preliminary Study"
                            />
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/empirical-study">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                An Empirical Study of Explainable AI Techniques<br></br> on Deep
                                Learning Models For Time Series Tasks
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2020-empirical-study/design-space.png"
                                alt="An Empirical Study"
                            />
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/deep-dive">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                A Deep Dive into Perturbations as Evaluation Technique for Time
                                Series XAI
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2023-deep-dive/deep-forda-card.png"
                                alt="A Deep Dive"
                            />
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/asi">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                Introducing the Attribution Stability Indicator: a Measure for Time
                                Series XAI Attributions
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2023-asi/asi-scores.png"
                                alt="The Attribution Stability Indicator"
                            />
                        </Link>
                    </Col>
                    {/* <Col sm={1}></Col> */}
                </Row>

                <Row className="divider"></Row>

                <Row sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Interactive Visual time Series Explanations</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row sm={12}>
                    {/* <Col sm={1}></Col> */}
                    <Col sm={3}>
                        <Link to="/debugging">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                Towards Visual Debugging for Multi-Target Time Series Classification
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2020-debugging/workflow_targets.png"
                                alt="Visual Debugging"
                            />
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/davots-1">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                Interactive dense pixel visualizations for time series and model
                                attribution explanations
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2023-davots-1/davots-1-overview.png"
                                alt="DAVOTS Overview"
                            />
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/davots-2">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                Dense-Pixel Visualizations and Ordering Strategies <br></br> for
                                Time Series Model Attributions using Pattern Detection Measures
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2025-davots-2/davots-2-overview.png"
                                alt="DAVOTS Overview"
                            />
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/vaec">
                            <button variant="outlined" style={{ padding: 10, width: '100%' }}>
                                Visual Explanations with Attributions and Counterfactuals on Time
                                Series Classification
                            </button>
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2024-veac/veac-workflow.png"
                                alt="Visual Explanations with Attributions and Counterfactuals on Time
                                Series Classification"
                            />
                            <img
                                style={{ aspectRatio: 16 / 9 }}
                                className="project-img"
                                src="images/2024-veac/veac-application.png"
                                alt="Visual Explanations with Attributions and Counterfactuals on Time
                                Series Classification"
                            />
                        </Link>
                    </Col>
                    {/* <Col sm={1}></Col> */}
                </Row>
            </Container>
        </section>
    );
}


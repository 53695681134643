import { React, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './vis-for-att.css';
import '../components.css';

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}

export function VisForAtt() {
    return (
        <section id="vis-for-att" className="components">
            <ScrollToTopOnMount />

            <div className="return">
                <Link to="/">[ Return ]</Link>
            </div>

            <Container fluid className="logos" style={{ marginTop: '60px' }}>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}>
                        <img className="logo" src="images/logos/dbvis-logo.png" alt="DBVIS Logo" />
                    </Col>
                    <Col></Col>
                    <Col sm={2}>
                        <img className="logo" src="images/logos/unikn-logo.png" alt="UNI KN Logo" />
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h1>Time series model attribution visualizations as explanations</h1>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col>
                        <h3>
                            Udo Schlegel<sup>1</sup>, Daniel A. Keim<sup>1</sup>
                        </h3>
                        <h3>
                            <sup>1</sup> University of Konstanz
                        </h3>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col className="text-align-center" sm={8}>
                        <a href="https://trexvis.github.io/Workshop2021/papers.html">
                            [ Workshop on TRust and EXpertise in Visual Analytics (TREX @ IEEE VIS),
                            New Orleans, USA ]
                        </a>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col className="text-align-center">
                        <a href="https://arxiv.org/pdf/2109.12935.pdf">[ Paper ]</a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://arxiv.org/abs/2109.12935">[ arXiv ]</a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="data/TREX-2021-Presentation.pdf">[ Presentation ]</a>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Heatmap-based Explanation Visualizations for Time Series XAI</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            <span className="bold">Heatmap-based explanation visualizations</span>{' '}
                            are a common technique in time series explainable AI (XAI) for
                            illustrating model attributions. These visualizations can be displayed
                            in different forms, such as showing the{' '}
                            <span className="bold">
                                relevance of each time point as a color gradient on the time points'
                                color
                            </span>{' '}
                            in the line plot, as a <span className="bold">pixel visualization</span>{' '}
                            representing the relevance of each time point again as a gradient, or as
                            a{' '}
                            <span className="bold">
                                line plot of the data with the attribution relevance in the
                                background
                            </span>{' '}
                            as a gradient color. However, as Jeyakumar et al. [1] pointed out, these
                            explanations can be{' '}
                            <span className="bold">
                                difficult for non-expert users to understand
                            </span>
                            .
                        </p>
                        <p></p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2021-attribution-visualizations-as-explanations/vis-for-att-1.png"
                            alt="Attribution Visualization: Part 1"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Enhanced Explanation Visualizations for Time Series XAI</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2021-attribution-visualizations-as-explanations/vis-for-att-2.png"
                            alt="Attribution Visualization: Part 2"
                        />
                    </Col>
                    <Col>
                        <p>
                            The visualizations combine line plots with background relevance heatmaps
                            to intuitively highlight critical time points. This approach contrasts
                            with traditional heatmaps by integrating data and relevance scores
                            within the same plot, making it easier to understand the temporal
                            relationships between input features and model predictions. These
                            enhanced visualizations aim to make complex attributions more
                            accessible, particularly for non-expert users, by providing clearer and
                            more contextually rich explanations. For instance,{' '}
                            <span className="bold">
                                a pipe is displayed around the line plot, with its color
                                representing a gradient of the relevance, and its thickness
                                indicating the strength of relevance
                            </span>
                            . Or,{' '}
                            <span className="bold">
                                positive or negative relevance can be visualized above or below the
                                time points
                            </span>{' '}
                            to further clarify the relevance.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>A Two Stage Workflow for Time Series XAI</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            However, these visualizations still have limitations in terms of{' '}
                            <span className="bold">
                                understandability and the interactions provided to facilitate easier
                                comprehension of model behavior
                            </span>
                            . We advocate for using{' '}
                            <span className="bold">counterfactual explanations</span> for time
                            series data, beneficial to{' '}
                            <span className="bold">both experts and nonexperts</span> due to their
                            strong alignment with human decision-making processes. Counterfactuals,
                            which{' '}
                            <span className="bold">
                                slightly alter an input to change its prediction
                            </span>
                            , provide an intuitive way to understand a model’s behavior.{' '}
                            <span className="bold">Building on the Shneiderman Mantra</span>, we
                            propose an approach that begins with visualizing the{' '}
                            <span className="bold">
                                original time series alongside its counterfactuals for an initial
                                overview
                            </span>
                            , followed by a more{' '}
                            <span className="bold">
                                detailed analysis using attribution visualizations
                            </span>
                            . Interaction techniques, such as{' '}
                            <span className="bold">what-if analyses</span>, enable deeper
                            exploration, enhancing the understanding of decision boundaries and
                            model robustness. This method{' '}
                            <span className="bold">
                                combines the strengths of counterfactuals and attributions
                            </span>
                            , addressing their respective limitations and improving
                            interpretability.
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2021-attribution-visualizations-as-explanations/vis-for-att-3.png"
                            alt="Attribution Visualization: Part 3"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center">
                    <Col sm={1}></Col>
                    <Col>
                        <p className="references">
                            [1] Jeyakumar, J. V., Noor, J., Cheng, Y. H., Garcia, L., & Srivastava,
                            M. (2020). How can i explain this to you? an empirical study of deep
                            neural network explanation methods. Advances in neural information
                            processing systems, 33, 4211-4222.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Container>
        </section>
    );
}

